import { Component} from '@angular/core';

import {Unsub} from "@app/shared";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsub {

  constructor(private translate: TranslateService
  ) {
    super();
    //this.setLanguage("english")
  }

  setLanguage(lang: string) {
    this.translate.addLangs([lang]);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
}
